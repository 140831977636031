<template>
  <div class="center_top" >
    <div class="ml_topzzz"><span>充电可视化</span></div>
    <div class="cen_main" id="mianright">
      <div class="main_left" v-if="datalist">
        <div class="left_item">
          <div class="item_main">
            <span>
              <span class="money">{{ datalist.totalCapacity?datalist.totalCapacity:0 }}</span
              >&nbsp;KW</span
            >
            <span>配电总额</span>
          </div>
          
        </div>
        <div class="left_item">
          <div class="item_main">
            <span>
              <span class="money">{{ datalist.totalAmount?datalist.totalAmount:0.00}}</span
              >&nbsp;元</span
            >
            <span>充值总额</span>
          </div>
         
        </div>
        <div class="left_item">
          <div class="item_main">
            <span> <span class="money">{{datalist.totalCD?datalist.totalCD:0}}</span>&nbsp;次</span>
            <span>充电次数累计</span>
          </div>
         
        </div>
      </div>
      <div  class="main_right" ref="mianright">
        <china :datacitylist="datacitylist" :chinacss ='chinacss'></china>
      </div>
    </div>
  </div>
</template>

<script setup>
import china from "./china.vue";
import { ref, reactive,onMounted } from "vue";

import { getListAPI, getcity } from "@/apis/api.js";

const datalist = ref(null);
const datacitylist = reactive([]);
const mianright=ref(null)
const chinacss =ref({
  width:'1000px',
  height:'465px'
})
onMounted(() => {
  getListAPI().then((res) => {
    // console.log(JSON.parse(res.data.cpgStat));
    datalist.value = res.data;
  });
  getcity().then((res) => {
    console.log(res);
    res.forEach((item) => {
      datacitylist.push([item.longitude, item.latitude]);
    });
  });
  var div = document.getElementById('mianright');
  chinacss.value.height =div.offsetHeight + 'px';
  chinacss.value.width =div.offsetWidth + 'px';
    console.log( mianright.value);


    // console.log(mianright.value.clientHeight,mianright.value.clientWidth);
  window.addEventListener('resize',()=>{
  var div = document.getElementById('mianright');
  chinacss.value.height =div.offsetHeight + 'px';
  chinacss.value.width =div.offsetWidth + 'px';
  // console.log(mianright.value.offsetHeight,mianright.value.offsetWidth )
  // var aaaa = this.$refs.mianright.offsetHeight
  // console.log(aaaa);
  })
});
</script>

<style lang="scss" scoped>
.center_top {
  // box-sizing: border-box;
  width: 100%;
  height: 100%;
  // background-image: url("../../assets/img/itembg1.png");
  // background-size: 100% 100%;
  // border: 2px solid #4ADEFE;

  border: 2px solid #2e90fd;
  border-radius: 20px;

  display: flex;
  flex-direction: column;

  .ml_topzzz {
    width: 100%;
    border-radius: 25px 25px 0 0;
	height: 9%;
    background-image: url("../../assets/img/item_top.png");
    background-size: 100% 100%;
    padding: 0px 0 0 15px;
    font-family: yahei;
    font-size: 26px;
    // line-height: 55px;
    color: #fff;
    span {
      // display: inline-block;
      // position: relative;
      // border: 1px solid red;
      background: -webkit-linear-gradient(left,#fff, #2E90FD);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
  }
  .cen_main {
	  position:relative;
	height:91%;
    width: 100%;
    display: flex;
    .main_left {
		z-index: 9999;
     position: absolute;
	 top: 0;
	 left: 0;
      // width: 400px;
	  width: 100%;
      // border-radius: 35px 35px 0 0;

      // border: 1px solid aqua;
      padding: 0 0 10px 15px;
      display: flex;
	  justify-content: space-around;
      .left_item {
        // width: 50%;
        // min-height: 25%;
        // background: url('../../assets/img/cenitem_bg.png');
        // background-size: cover;
        position: relative;
        // border: 1px solid aqua;

          // border:1px solid #000;
          &:nth-child(6){
            position: relative;
            left: -23px;
            // border: 2px solid red;
          }
           &:nth-child(5){
             position: relative;
            left: -20px;
            top: 5px;
            // border: 2px solid red;
          }
        .item_main {
          padding-top: 5px;
          // position: absolute;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          span {
            font-size: 16px;
            color: #ffffff;
            font-family: extr;
            display: inline;
          }
          .money {
            font-size: 22px;
            font-family: medium;
            color: #ffffff;
          }
        }
        .cenitem_bg {
          width: 120px;
          height: 45px;
          position: absolute;
          bottom: 15px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
    .main_right {
      flex: 1;
      height: 100%;
      // border: 1px solid red;
    }
  }
}
</style>