<template>
  <div class="container" ref="contain" style="overflow: hidden;">
    <div class="container_img">
      <div class="header" style="height: 8%;">
        <div class="head_top all">
          <div class="top_left cenal">
			<img style="width: 250px;height: 75%;margin-left: 20px;" src="../assets/img/logow.01250ef.png" alt="">
            <!-- 充电可视化看板 -->
            <!-- <img src="../assets/img/zhuanshi.png" alt="" class="top_img" /> -->
          </div>
		  <div class="" style="font-family: yahei;font-size: 0.18rem;font-weight:bold;color: #fff;">
			  充电可视化看板
		  </div>
          <div class="top_right cenal">
            <span
              >{{ times.year }} 年&nbsp;<span class="con">{{
                times.month
              }}</span>
              月 <span class="con"> {{ times.date }} </span> 日 &nbsp; 星期
              <span class="con">{{ sunday[times.setdate] }}&nbsp;</span>
              <span class="con"
                >&nbsp;{{ times.hours }}:{{ times.minutes }}:{{
                  times.seconds
                }}</span
              ></span
            >
            <img
              src="../assets/img/top_icon.png"
              alt=""
              class="top_icon"
              @click="chang"
            />
          </div>
          <!-- <img src="../assets/img/top_icon.png" alt="" class="top_icon" /> -->
        </div>
        <img src="../assets/img/top_line.png" alt="" class="head_fot" />
      </div>
      <div class="main" style="height: 92%;padding-bottom: 0%">
        <div class="cen_left con_item" style="height: 100%;width: 22%;margin-right:1%;margin-left:0.5%;">
          <div class="left_item" style="height: 49%;margin-bottom: 2%">
            <topleft />
          </div>
          <div class="left_item" style="height: 49%;">
            <fotleft />
          </div>
        </div>
        <div class="cen_cen con_item" style="height: 100%;width: 53%;margin-right:1%;">
          <div class="cenitem_top" style="height: 68%;margin-bottom: 2%;"><topcen /></div>
          <div class="cenitem_fot" style="height: 30%;"><fotcen /></div>
        </div>
        <div class="cen_right con_item" style="height: 100%;width: 22%;margin-right:0.5%;">
          <div class="right_item" style="height: 31%;"><topright /></div>
          <div class="right_item" style="height: 31%;margin: 3.5% 0;"><centright /></div>
          <div class="right_item" style="height: 31%;"><fotright /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { toggle } from "../js/full";
import topleft from "./left/top";
import fotleft from "./left/foot";
import topright from "./right/top";
import centright from "./right/center";
import fotright from "./right/fot";
import topcen from "./center/top.vue";
import fotcen from "./center/fot.vue";

// import { getListAPI } from "@/apis/api.js";
const sunday = reactive(["日", "一", "二", "三", "四", "五", "六"]);
const times = reactive({
  year: '2023',
  month: '11',
  date: '04',
  hours: '11',
  minutes: '06',
  setdate: '六', //周几
  seconds: '22',
});
const contain = ref(null);
const chang = () => {
  console.log(contain.value);
  toggle(contain.value);
  // location.reload()
};
const getdattime = () => {
  //  setTimeout(()=>{
  times.year = new Date().getFullYear();
  times.month =
    new Date().getMonth() + 1 <= 9
      ? "0" + (new Date().getMonth() + 1)
      : new Date().getMonth() + 1;
  times.date =
    new Date().getDate() <= 9
      ? "0" + new Date().getDate()
      : new Date().getDate();
  times.hours =
    new Date().getHours() + 1 <= 9
      ? "0" + new Date().getHours()
      : new Date().getHours();
  times.minutes =
    new Date().getMinutes() <= 9
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes();
  times.setdate = new Date().getDay();
  // },1000)

  // times.hours =
  //   new Date().getHours() + 1 <= 9
  //     ? "0" + new Date().getHours()
  //     : new Date().getHours();
  // times.minutes =
  //   new Date().getMinutes() <= 9
  //     ? "0" + new Date().getMinutes()
  //     : new Date().getMinutes();
  times.seconds =
    new Date().getSeconds() <= 9
      ? "0" + new Date().getSeconds()
      : new Date().getSeconds();
};

const myrefresh = () => {
//  window.location.reload(true);
// window.location.href = window.location.origin + window.location.pathname;
   location.reload()
};
  // setTimeout(myrefresh(), 1000)
onMounted(() => {
  // myrefresh()
  // window.location.reload();
//   window.addEventListener('resize', function() {
//       // alert('已监听到页面宽度发生变化')
//       window.location.reload();
// })
  console.log('mei1');
if(location.href.indexOf("#reloaded")==-1){
        location.href=location.href+"#reloaded";
        console.log('shuxinl');
        location.reload();
    }

  setInterval(() => {
    getdattime();
  }, 1000);

  // getListAPI().then((res) => {
  //   console.log(res);
  // });
});
// const msg = ref("充电可视化看板");
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
.all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cenal {
  display: flex;
  align-items: center;
}
// .flcol {
//   display: flex;
//   flex-direction: column;
// }
.container {
  width: 100vw;
  height: 100vh;
  font-family: yahei95w;
  background: radial-gradient(circle at center, #1b4b87, #050827);
  box-sizing: border-box;
}
.container_img {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: url("../assets/img/bg1.png") 100% 100% no-repeat;
  padding-bottom: 15px;
  // border: 1px solid red;
}
.header {
  width: 100%;
  height: 80px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .head_top {
    flex: 1;
    padding: 0 20px;
    .top_left {
      font-family: yahei;
      font-size: 40px;
      color: #fff;
      .top_img {
        width: 120px;
        height: 8px;
        margin-left: 12px;
      }
    }
    .top_right {
      font-family: yahei;
      color: #2e90fd;
      font-size: 20px;
      .con {
        color: #fff;
      }
      .top_icon {
        width: 32px;
        height: 32px;
        margin-left: 20px;
      }
    }
  }
  .head_fot {
    width: 100%;
    height: 8px;
  }
}
.main {
  flex: 1;
  width: 100%;
  box-sizing: border-box;

  // border: 1px solid red;
  // padding: 19px 0;
  display: flex;
  justify-content: space-between;
  .con_item {
    width: 22vw;
    // height: 100vh;
    /* margin: 0 28px; */
    // border: 1px solid red;
    &:nth-child(2) {
      width: 53vw;
      margin: 0;
    }
  }
  .cen_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* .left_item {
      flex: 1;
      &:nth-child(1) {
        margin-bottom: 8px;
      }
      &:nth-child(2) {
        margin-top: 8px;
      }
    } */
  }
  .cen_right {
    display: flex;
    flex-direction: column;
    // height: 100%;
    .right_item {
      // height: 31%;
      flex: 1;
      // height: 390px;
      // overflow: hidden;
      // min-height: 20%;
      width: 100%;
      // border: 1px solid aqua;
      
    }
  }
  .cen_cen {
    display: flex;
    flex-direction: column;
    .cenitem_top {
      width: 100%;
      height: 66%;
      /* margin-bottom: 15px; */
    }
    .cenitem_fot {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
