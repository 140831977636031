import http from './request.js'
/**
 *  @param '/api'代表config中index.js中配置的代理
 */
export function getListAPI() {return http({
    url: "api/BigShow/GetData",
    method: "get",
  })
}
export function getcity() { return http({
    url: "/api/BigShow/ChargePointGroupInfo",
    method: "get",
  })}

export function getMoney(id){
  return http({
      url:`/api/BigShow/GetDataSummary?year=${id}`,
      method:"get",
     
  })
}

export function getdianliang(){
  return http({
      url:`/api/BigShow/GetLastSevenMeter`,
      method:"get",
     
  })
}
export function GetLastChargeTime(){
  return http({
      url:`/api/BigShow/GetLastChargeTime`,
      method:"get",
     
  })
}
export function getdianbao(){
  return http({
      url:`/api/BigShow/GetDBList`,
      method:"get",
     
  })
}

export function getbaobiaoshuju(){
  return http({
      url:`/api/BigShow/GetChargePointGroupStatisticsData`,
      method:"get",
     
  })
}
export function GetOnlineChargeUsers(){
  return http({
      url:`/api/BigShow/GetOnlineChargeUsers`,
      method:"get",
     
  })
}
export function GetMonthBalancelog(){
  return http({
      url:`/api/BigShow/GetMonthBalancelog`,
      method:"get",
     
  })
}

// export function getmone(){
//   return http({
//       url:`/api/BigShow/GetLastSevenMeter`,
//       method:"get",
     
//   })
// }

