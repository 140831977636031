/**
 * ajax请求配置
 */
import axios from 'axios'
//  import { Message } from 'element-ui'
 
// import qs from 'qs'
 // axios默认配置
 axios.defaults.timeout = 10000 // 超时时间
 // axios.defaults.baseURL 请求地址前缀
 // 微服务地址
//  axios.defaults.headers={ //请求头
//     'showkey': "1",
// },
 axios.defaults.baseURL = 'https://power-tibox.cn'//'http://192.168.0.148'
 
//  https://chongdian.zifutiaodong.com  https://yw.52kfw.cn  https://power-tibox.cn
 // 整理数据
 axios.defaults.transformRequest = function(data) {
     data = JSON.stringify(data)
     return data
 }
 
 // 路由请求拦截
 axios.interceptors.request.use(
     config => {
         config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        //  config.headers['showkey'] = 1
        //  config.headers['Authorization'] = 'ASP.NET_SessionId=id5nrgpvjztvgiqm5yp4pmgt'
         return config
     },
     error => {
         return Promise.reject(error.response)
     })
 
 // 路由响应拦截
 axios.interceptors.response.use(
     response => {
         if (response.data.success === false) {
             return Message.error(response.data.errDesc)
         } else {
             return response.data
         }
     },
     error => {
         return Promise.reject(error.response) // 返回接口返回的错误信息
     })
 export default axios
 
 
