<template>
  <div class="main_leftss" ref="topall">
    <div class="ml_top" ref="topbox"><span>当前在线充电用户</span></div>
    <div class="right_topsss" id="chartssss" ref="chartsssss" :style="listbox">
      <div ref="scrollContainer" class="scroll_container">
		<div v-for="(item, index) in report" :key="index" class="scroll_item">
          <div class="item">
            <!--:class="index === 3?'con':''"  -->
            <span
              ><span style="margin-right: 10px"> | </span> {{ item.TransactionId }}</span
            >
            <span> {{ item.PhoneNum }}</span>
            <span> {{ item.StartTimestamp }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  nextTick,
} from "vue";
// import { getdianbao } from "@/apis/api";
import { GetOnlineChargeUsers } from "@/apis/api";
const timer = ref(null);
const scrollContainer = ref(null);
//ref绑定初始化
// let roll = ref(null)
const topall = ref(null);
const topbox = ref(null);
const listbox = reactive({
  height: "220px",
});
const report = reactive([]);

onBeforeUnmount(() => {
  clearTimeout(timer.value);
});
onUnmounted(() => {
  clearTimeout(timer.value);
});

//  onMounted(()=> {
//   start()
// })
const start = () => {
  //清除定时器
  clearTimeout(timer.value);
  //定时器触发周期
  let speed = ref(75);
  timer.value = setInterval(ListScroll, speed.value);
};
const ListScroll = () => {
  let scrollDom = scrollContainer.value;
  // 判读组件是否渲染完成
  if (scrollDom.offsetHeight == 0) {
    scrollDom = scrollContainer.value;
    // start()
  } else {
    // 如果列表数量过少不进行滚动
    if (scrollDom.children.length < 4) {
      clearTimeout(timer.value);
      return;
    }
    // 组件进行滚动
    scrollDom.scrollTop += 1;
    // 判断是否滚动到底部
    if (
      Math.floor(scrollDom.scrollTop + 1) ==
      scrollDom.scrollHeight - scrollDom.clientHeight
    ) {
      // 获取组件第一个节点
      let first = scrollDom.children[0];
      // 删除节点
      scrollDom.removeChild(first);
      // 将该节点拼接到组件最后
      scrollDom.append(first);
      // start()
    }
  }
};

onMounted(() => {
  nextTick(() => {
    listbox.height =
      topall.value.clientHeight - topbox.value.clientHeight + "px";
    // console.log(listbox.he);
    setTimeout(() => {
      start();
    }, 500);
  });
     GetOnlineChargeUsers().then((res) => {
      // this.report = res.data;
	  for(var x in res){
		  res[x].StartTimestamp = res[x].StartTimestamp.replace('T',' ')
		  report.push(res[x])
	  }
      // setTimeout(() => {
      //   this.textSlide();
      // }, 2000);
    });
  // console.log(report);
});
</script>

<style lang="scss" scoped>
.main_leftss {
  width: 100%;
  height: 100%;
  position: relative;
  // border:5px solid rgb(32, 121, 66);
  background-image: url("../../assets/img/itembg.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;

  .ml_top {
    // border: 1px solid aqua;
    width: 100%;
    height: 55px;
    background-image: url("../../assets/img/item_top.png");
    background-size: 100% 100%;
    padding: 5px 0 0 15px;
    border-radius: 25px 25px 0 0;

    font-family: yahei;
    font-size: 26px;
    //  background: linear-gradient(to right, #fff, #2e90fd);
    color: #fff;
      span {
      // display: inline-block;
      // position: relative;
      // border: 1px solid red;
      background: -webkit-linear-gradient(left,#fff, #2E90FD);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
  }

  .right_topsss {
    // flex: 1;

    height: 0;
    // position: relative;
    width: 100%;
    // border: 5px solid pink;
    overflow: hidden;

    padding: 10px 25px 20px 25px;

    .scroll_container {
      width: 100%;
      height: 100%;

      overflow: hidden;

      .scroll_item {
        height: 34px;
        margin-bottom: 10px;
        // background-color: skyblue;
        color: #fff;
        font-size: 16px;
        font-family: jian;
        border-bottom: 1px dashed rgba(61, 115, 255, 0.5);
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.con {
            color: #ff9900;
          }
        }
      }
    }
  }
}
</style>