<template>
  <div>
    <div id="container" ref="myMap" class="border" :style="props.chinacss"></div>
  </div>
</template>
<script setup>
// import { defineComponent } from '@vue/composition-api'

// export default defineComponent({
//   setup() {

//   },
// })

import AMapLoader from "@amap/amap-jsapi-loader";
/*在Vue3中使用时,需要引入Vue3中的shallowRef方法(使用shallowRef进行非深度监听,
因为在Vue3中所使用的Proxy拦截操作会改变JSAPI原生对象,所以此处需要区别Vue2使用方式对地图对象进行非深度监听,
否则会出现问题,建议JSAPI相关对象采用非响应式的普通对象来存储)*/
import { reactive, shallowRef } from "@vue/reactivity";
import { ref, onMounted } from "vue";
// import func from "../../../vue-temp/vue-editor-bridge";
// import func from '../../../vue-temp/vue-editor-bridge';
// const map = shallowRef(null);
const props = defineProps(["datacitylist","chinacss"]);
// const chinacss =ref({
//   width:'700px',
//   height:'485px'
// })
const myMap=ref(null)
const path = ref([]);
const current_position = ref([]);

function initMap() {
  window._AMapSecurityConfig = {
    securityJsCode: "8e920f73eb2e6880a92ea6662eefc476",
  };
  AMapLoader.load({
    key: "75cd505b5f96946321e1f10334b971c2", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  })
    .then((AMap) => {
      const map = new AMap.Map("container", {
        //设置地图容器id
        // viewMode:"3D",    //是否为3D地图模式
        zoom: 4, //初始化地图级别 
        center: [105.628792, 32.813064], //初始化地图中心点位置32.813064,105.628792    120.12438, 30.22478
        mapStyle: "amap://styles/blue",
      });
      let src = require(`@/assets/img/icon.png`);
      // // for(i=0;i<=2;i++){

      // const marker = new AMap.Marker({
      //   icon: new AMap.Icon({
      //     image: src,
      //     imageSize: new AMap.Size(25, 25),
      //   }),
      //   position: [120.12438, 30.22478], // 这里我们通过上面的点击获取经纬度坐标,实时添加标记
      //   // 通过设置 offset 来添加偏移量
      //   offset: new AMap.Pixel(-26, -54),
      //   // map:map
      // });
      // // }
      // marker.setMap(map);

      setMapMarker();

      function setMapMarker() {
        // 创建 AMap.Icon 实例
        let icon = new AMap.Icon({
          size: new AMap.Size(36, 36), // 图标尺寸
          image: src, // Icon的图像
          imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        });
        let makerList = [];
        // let tshsadj =[{lag:120.12438,log:30.22478},{lag:110.12438,log:40.22478},{lag:115.12438,log:44.22478}]
        props.datacitylist.forEach((item) => {
          // 遍历生成多个标记点
          let marker = new AMap.Marker({
            map: map,
            zIndex: 9999999,
            icon: icon, // 添加 Icon 实例
            offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
            position: item, // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
          });
          makerList.push(marker);
        });
        map.add(makerList);
        // 自动适应显示想显示的范围区域
        //  map.setFitView();
      }
    })
    .catch((e) => {
      console.log(e);
    });
}

onMounted(() => {
  // console.log(myMap.value.clientHeight);
  // setTimeout(() => {
    console.log(props.chinacss);
    initMap();
  // }, 500);
});
</script>

<style lang="scss" scoped>
.border {
  // width: 700px;
  // height: 485px;
  // border-radius: 15px;
  border-bottom-right-radius: 25px;
  background: transparent;
}
// .border {
//   width: 100%;
//   height: 100%;
//   background: transparent;
// }
</style>
