import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/font/font.css'
import './lib/flexible'
// import echarts from 'echarts'
import * as echarts from 'echarts'
import china from '@/assets/mapsJson/china.json';
const app =createApp(App)
echarts.registerMap('china', china);
// echarts.registerTheme('customTheme', {
//     grid: {
//       show: false, // 隐藏网格背景图
//       containLabel: true,
//       left: 0,
//       right: 0,
//       top: 0,
//       bottom: 0,
//       backgroundColor: 'transparent', // 设置背景透明
//       borderWidth: 0 // 设置网格线宽度为0, 表示没有线条
//     },
//   });
app.config.globalProperties.$echarts = echarts

app.mount('#app')
