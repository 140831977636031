<template>
  <div class="main_left">
    <div class="ml_top"><span>近7日平均充电次数统计</span></div>
    <div class="left_fot" id="chart" ref="chart"></div>
  </div>
</template>

<script setup>
// 引入echarts
import * as echarts from "echarts";
import { reactive, ref, onMounted } from "vue";
import { GetLastChargeTime } from "@/apis/api.js";
const chart = ref();
// 引入提示框，标题，直角坐标系等组件，组件后缀都为 Component
// const data = reactive([220, 282, 188, 188, 199, 255, 220]);
// const datatimes = reactive([]);
const echartInit = () => {
	GetLastChargeTime().then(res=>{
		console.log('123',res)
		var data = [];
		var datatimes = []
		for(var x in res){
			data.push(res[x])
			datatimes.push(x)
		}
		var myChart = echarts.init(chart.value);
		// const canvasHeight = 1200; // 可根据需要调整
		window.addEventListener('resize',()=>{
		  myChart.resize()
		})
		//   myChart.getDom().style.height = canvasHeight + 'px';
		// 指定图表的配置项和数据
		var option = {
		  grid: {
		    show: false,
		    left: "3%",
		    right: "4%",
		    top: "20%",
		    bottom: "20%",
		    containLabel: true,
		  },
		
		  xAxis: [
		    {
		      splitLine: {
		        show: false, // 隐藏X轴网格线
		      },
		      axisLine: {
		        show: false,
		      },
		      axisLabel: {
		        textStyle: {
		          color: "#fff",
		          fontSize: 12,
		        },
		      },
		
		      // type: "category",
		      axisTick: { show: false },
		      data: datatimes,
		    },
		  ],
		  yAxis: [
		    {
		      type: "value",
		      name: "单位: 次",
		    nameTextStyle: {
		      // position:reactive,
		      
		            fontSize:12,
		            
		            color: "rgba(255,255,255,0.6)"
		          },
		      splitLine: {
		        show: false, // 隐藏X轴网格线
		      },
		      axisLabel: {
		        textStyle: {
		          color: "#fff",
		          fontSize: 12,
		        },
		      },
		    },
		    // {
		    //   type: "value",
		    //   name: "单位:次",
		    //   position: "left",
		    //   alignTicks: true,
		    //   axisLabel: {
		    //     textStyle: {
		    //       color: "#fff",
		    //       fontSize: 2,
		    //     },
		    //   },
		    // },
		    // axisLine: {
		    //   show: true,
		    //   lineStyle: {
		    //     color: colors[2],
		    //   },
		    // },
		    // axisLabel: {
		    //   formatter: '{value} °C'
		    // }
		  ],
		  series: [
		    {
		      type: "bar",
		      showBackground: true,
		      barWidth: 11,
		      itemStyle: {
		        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
		          { offset: 0, color: "#83bff6" },
		          { offset: 0.5, color: "#188df0" },
		          { offset: 1, color: "#188df0" },
		        ]),
		        // color:{
		        //   image:''
		        // }
		      },
		
		      data: data,
		    },
		  ],
		};
		// 使用刚指定的配置项和数据显示图表。
		myChart.setOption(option);
	})
  
};


onMounted(() => {
  //  for (let i = 0; i <= 6; i++) {
  //   datatimingfun(i);
  // }
  echartInit();
});
</script>


<style lang="scss" scoped>
// // #chart .echarts {
// //   background-color: transparent !important; /* 设置背景透明 */
// transform: scaleY(1.5)
// // }
// .left_fot  canvas {
//   transform: scale(2)
// }

.main_left {
  width: 100%;
  height: 100%;
  position: relative;
  // border:5px solid rgb(32, 121, 66);
  background-image: url("../../assets/img/itembg.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;

  .ml_top {
    // border: 1px solid aqua;
    width: 100%;
    height: 55px;
    background-image: url("../../assets/img/item_top.png");
    background-size: 100% 100%;
    padding: 5px 0 0 15px;

    font-family: yahei;
    font-size: 26px;
    //  background: linear-gradient(to right, #fff, #2e90fd);
    color: #fff;
     span {
      // display: inline-block;
      // position: relative;
      // border: 1px solid red;
      background: -webkit-linear-gradient(left,#fff, #2E90FD);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
  }
  .left_fot {
    flex: 1;
    // height:600px;
    width: 100%;
    // border: 1px solid red;
    // padding:20px;
    // color: transparent;
  }
}
</style>