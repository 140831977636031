<template>
  <div class="main_left" ref="allbox">
    <div class="ml_top" ref="boxtop"><span>电表分布</span></div>
    <div class="right_centes" id="rightcen" ref="rightcens" :style="listbox">
      <div ref="scrollcen" class="scrollcenss">
        <div v-for="(item, index) in report" :key="index" class="scroll_item">
          <div class="item">
            <!--:class="index === 3?'con':''"  -->
            <span
              ><span style="margin-right: 10px"> | </span>
              {{ item.groupName }}</span
            >
            <span> {{ item.chargeBoxName }}</span>
            <!-- <span>  {{  item.time }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  nextTick,
  watch,
} from "vue";
import { getdianbao } from "@/apis/api";

const timer = ref(null);
const scrollcen = ref(null);
const listbox = reactive({
  height: "220px",
});
//ref绑定初始化
const allbox = ref(null);
const boxtop = ref(null);
const rightcens = ref(null);
// let roll = ref(null)
const report = ref(null);

//   {
//     name: "中昆******广场",
//     info: " 158****8312",
//     place: "中昆26栋最后一个",
//     time: "2023-07-15 11:11",
//   },
// ]);

onBeforeUnmount(() => {
  clearTimeout(timer.value);
});
onUnmounted(() => {
  clearTimeout(timer.value);
});

//  onMounted(()=> {
//   start()
// })
const start = () => {
  //清除定时器
  clearTimeout(timer.value);
  //定时器触发周期
  let speed = ref(75);
  timer.value = setInterval(ListScroll, speed.value);
};
const ListScroll = () => {
  // console.log(scrollcen.value);
  let scrollDom = scrollcen.value;
  // 判读组件是否渲染完成
  if (scrollDom.offsetHeight == 0) {
    scrollDom = scrollcen.value;
    // start()
  } else {
    // 如果列表数量过少不进行滚动

    if (scrollDom.children.length < 4) {
      clearTimeout(timer.value);
      return;
    }
    // 组件进行滚动
    // console.log(scrollcen.value);

    // console.log("scrollTop",Math.floor(scrollDom.scrollTop+1));
    scrollDom.scrollTop += 1;
    // console.log((scrollDom.scrollHeight-scrollDom.clientHeight));
    // 判断是否滚动到底部
    if (
      Math.floor(scrollDom.scrollTop + 1) ==
      scrollDom.scrollHeight - scrollDom.clientHeight
    ) {
      // 获取组件第一个节点
      let first = scrollDom.children[0];
      // 删除节点
      scrollDom.removeChild(first);
      // 将该节点拼接到组件最后
      scrollDom.append(first);
      // console.log(scrollDom);
      // start()
    }
  }
};

onMounted(() => {
  nextTick(() => {
    listbox.height =
      allbox.value.clientHeight - boxtop.value.clientHeight + "px";
    // listbox.height=0 + 'px'
    // setTimeout(() => {
    //   start();
    // }, 500);
    getdianbao().then((res) => {
      // this.report = res.data;
      report.value = res.data;
      // console.log(res.data);
      // setTimeout(() => {
      //   this.textSlide();
      // }, 2000);
      start();
    });
  });
});
</script>
<style lang="scss" scoped>
.main_left {
  width: 100%;
  height: 100%;
  position: relative;
  // border:5px solid rgb(32, 121, 66);
  background-image: url("../../assets/img/itembg.png");
  background-size: 100% 100%;

  .ml_top {
    // border: 1px solid aqua;
    width: 100%;
    height: 55px;
    border-radius: 25px 25px 0 0;
    background-image: url("../../assets/img/item_top.png");
    background-size: 100% 100%;
    padding: 5px 0 0 15px;

    font-family: yahei;
    font-size: 26px;
    //  background: linear-gradient(to right, #fff, #2e90fd);
    color: #fff;
      span {
      // display: inline-block;
      // position: relative;
      // border: 1px solid red;
      background: -webkit-linear-gradient(left,#fff, #2E90FD);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
  }
  .right_centes {
    // flex: 1;
// 
    height: 0;
    // height: 100%;
    // position: relative;
    // border: 1px solid red;
    width: 100%;
    overflow: hidden;

    padding: 10px 25px 20px 25px;
    // color: transparent;
    // .report{
    //   width: 100%;
    //   height: 100%;
    //   .report_li{
    //     height: 50px;
    //     width: 100%;
    //   }
    // }
    .scrollcenss {
      width: 100%;
      height: 100%;
      // border: 1px solid red;

      overflow: hidden;

      .scroll_item {
        height: 34px;
        margin-bottom: 10px;
        // background-color: skyblue;
        color: #fff;
        font-size: 16px;
        font-family: jian;
        border-bottom: 1px dashed rgba(61, 115, 255, 0.5);
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.con {
            color: #ff9900;
          }
        }
      }
    }
  }
}
</style>