<template>
  <div class="main_left">
    <div class="ml_top"><span>用户充值分布</span></div>
    <div class="right_fot" id="charbin" ref="charbin"></div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import { reactive, ref, onMounted, nextTick } from "vue";
import { GetMonthBalancelog } from "@/apis/api";
const charbin = ref();
// const data = reactive(["小程序   70%", "APP  70%"]);
// const data = reactive([
//   { name: "支付宝", value: "72" },
//   { name: "微信", value: "18" },
// ]);
// const data=reactive(["小程序", "APP"])
const echartInit = () => {
  var myChart = echarts.init(charbin.value);
  window.addEventListener("resize", () => {
    myChart.resize();
  });
  GetMonthBalancelog().then(res=>{
	  var data = [
		{ name: "支付宝", value:res[0]},
		{ name: "微信", value: res[1]},
		]
	  var option = {
	    // color: ["#FF8900", "#00EAFF"],
	    // grid: {
	    //   show: false,
	    //   left: "0%",
	    //   // right: "4%",
	    //   // top: "15%",
	    //   // bottom: "10%",
	    //   // containLabel: true,
	    // },
	    legend: {
	      // data: ["小程序", "APP"],
	      // data: data,
	      // top: 15,
	      // right: 10,
	      itemWidth: 15,
	      itemHeight: 12,
	      formatter: (e) => {
	        let tarValue;
	        let total = 0;
	        for (let i = 0; i < data.length; i++) {
	          // tarValue = data[i].value;
	          total += data[i].value;
	          if (data[i].name == e) {
	            tarValue = data[i].value;
	          }
	        }
	        let v = tarValue;
	        return `${v}%      ${e}`;
	      },
	      // formatter: `{a} : {b}家 ({c}%)`,
	  
	      textStyle: {
	        color: "#fff",
	        fontSize: '16',
	      },
	      orient: "vertical",
	      right: 25,
	      top: 40,
	      bottom: 20,
	    },
	    // graphic: {
	    //   //图形中间图片
	    //   elements: [
	    //     {
	    //       type: "image",
	    //       style: {
	    //         // image: "../../assets/img/becbg.png",
	    //         image: require("../../assets/img/becbg.png"), // 自定义图片路径
	  
	    //         width: 60,
	    //         height: 60,
	    //       },
	    //       left: "17%",
	    //       top: "25%",
	    //     },
	    //   ],
	    // },
	  
	    series: [
	      {
	        name: "Access From",
	        type: "pie",
	        selectedMode: "single",
	        radius: [0, "40%"],
	        center: ["30%", "50%"],
	        //  avoidLabelOverlap: false,
	        //             legendHoverLink: false,
	        //             hoverOffset: 4,
	        label: {
	          position: "center",
	          fontSize: 0,
	          color: "#fff",
	          show: true,
	          formatter() {
	            // return `  ${10000} \n 本月充值总额`;
	            return `{percent|${res[2]}} \n {sexName|${"本月充值金额"}}`;
	          },
	          // formatter: '{b} : {b}家 ({c}%)',
	          align: "center",
	          color: "#ffffff",
	          verticalAlign: "middle",
	          // 此处重点，字体大小设置为0
	          // axisName:{
	  
	          fontSize: "0",
	          // }
	          rich: {
	            percent: {
	              fontSize: 22,
	              color: "#fff",
	            },
	            sexName: {
	              fontSize: 12,
	              color: "#ccc",
	              lineHeight: 24,
	            },
	          },
	        },
	        emphasis: {
	          label: {
	            show: true,
	            rich: {
	              percent: {
	                fontSize: 22,
	                color: "#fff",
	              },
	              sexName: {
	                fontSize: 8,
	                color: "#ccc",
	              },
	            },
	          },
	        },
	        color: { image: require("../../assets/img/becbg.png") },
	        // color:'transparent', //移动圆心之后图片可以定位对，但是在其他尺寸屏幕下很短对齐了
	  
	        labelLine: {
	          show: false,
	        },
	        data: [{ value: 1 }],
	      },
	      {
	        name: "Access From",
	        type: "pie",
	        radius: ["65%", "80%"],
	        center: ["30%", "50%"],
	  
	        avoidLabelOverlap: false,
	        itemStyle: {
	          // borderRadius: 2,
	          borderColor: "#0c1535",
	          borderWidth: 2,
	        },
	        color: ["#FF8900", "#00EAFF"],
	        label: {
	          show: false,
	          position: "center",
	        },
	        // emphasis: {
	        //   label: {
	        //     show: true,
	        //     fontSize: 40,
	        //     fontWeight: "bold",
	        //   },
	        // },
	        labelLine: {
	          show: false,
	        },
	        // data: [
	        //   { value: 7235, name: data[0] },
	        //   { value: 1048, name: data[1] },
	        // ],
	        data: data,
	        // roseType: 'radius',
	      },
	    ],
	  };
	  
	  myChart.setOption(option);
  })
  
};
onMounted(() => {
  nextTick(() => {
    echartInit();
  });
});
</script>

<style lang="scss" scoped>
.main_left {
  width: 100%;
  height: 100%;
  position: relative;
  // border:5px solid rgb(32, 121, 66);
  background-image: url("../../assets/img/itembg.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  .ml_top {
    // border: 1px solid aqua;
    width: 100%;
    height: 55px;
    border-radius: 25px 25px 0 0;

    background-image: url("../../assets/img/item_top.png");
    background-size: 100% 100%;
    padding: 5px 0 0 15px;

    font-family: yahei;
    font-size: 26px;
    //  background: linear-gradient(to right, #fff, #2e90fd);
    color: #fff;
    span {
      // display: inline-block;
      // position: relative;
      // border: 1px solid red;
      background: -webkit-linear-gradient(left, #fff, #2e90fd);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .right_fot {
    flex: 1;
    // height: 255px;
    // height:600px;
    width: 100%;
    // border: 1px solid red;
    // padding:20px;
    // overflow: auto;
    // resize: both;
  }
}
</style>